import { EMAIL_REGEX } from '../const';

/**
 * Simple handler to check whatever given string is a valid email address
 * @param {String} arg String to test
 * @returns {Boolean} Result of a test
 */
export default function isEmail(arg) {
  return EMAIL_REGEX.test(arg);
}
