const OPERATIONS = {
  eq: (source, tester) => source == tester,
  '<': (source, tester) => source <= tester,
  '>': (source, tester) => source >= tester,
  '%': (source, tester) =>
    Array.isArray(source)
      ? source.some((entry) => entry == tester)
      : new RegExp(tester, 'i').test(source)
};

export default function filterAplicator({ def, prop }) {
  return (item) => {
    const test = ({ key, op, val }) => OPERATIONS[op](item[key], val);
    return (def.length ? def.some(test) : true) && prop.every(test);
  };
}
