import React, { useMemo } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';
import isDateValid from '../util/isDateValid';
import formatDate from '../util/formatDate';
import Icon from '../Icon';

export default function DateTime(props) {
  const { data, format, className, icon, fallback } = props;
  const date = data instanceof Date ? data : new Date(data);
  const content = useMemo(
    () => (isDateValid(date) ? formatDate(date, format) : fallback),
    [date, format, fallback]
  );
  return (
    <time className={cn('DateTime', className)} dateTime={date}>
      {icon && <Icon icon={icon} />}
      {content}
    </time>
  );
}

assignDisplayName(DateTime);

DateTime.defaultProps = {
  icon: 'calendar',
  fallback: 'Incorrect date provided',
  format: 'YYYY-MM-DDTHH:mm:ss'
};

DateTime.propTypes = {
  /** Fallback text if something went wrong */
  fallback: PT.string,
  /** Date string ISO8601 (primary input) to be formatted */
  data: PT.oneOfType([PT.string, PT.instanceOf(Date)]).isRequired,
  /** ClassName for a root element */
  className: PT.string,
  /** Desired output format, moment.js compatible */
  format: PT.string,
  /** Icon - by default it's just a calendar */
  icon: PT.string
};
