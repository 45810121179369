import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';

import './style.css';

export default function CircularProgress(props) {
  const { className } = props;
  return (
    <svg viewBox='25 25 50 50' className={cn('CircularProgress', className)}>
      <circle
        cx='50'
        cy='50'
        r='20'
        className='CircularProgress-base'
        strokeWidth='3'
      />
      <circle
        cx='50'
        cy='50'
        r='20'
        className='CircularProgress-circle'
        strokeWidth='3'
      />
    </svg>
  );
}

assignDisplayName(CircularProgress);

CircularProgress.propTypes = {
  /** CSS class for a root <svg> element */
  className: PT.string
};
