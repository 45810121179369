import { useCallback, useEffect } from 'react';

export default function useAnimationEnd($ref, animation, callback) {
  const handler = useCallback(
    (event) => {
      if (event.animationName === animation) {
        callback(event);
      }
    },
    [callback, animation]
  );

  useEffect(() => {
    const $el = $ref.current;
    if ($el) {
      $el.addEventListener('animationend', handler);
    }
    return () => {
      if ($el) {
        $el.removeEventListener('animationend', handler);
      }
    };
  }, [$ref, handler]);
}
