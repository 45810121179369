/**
 * Create string with literal description of number value e.g. K for 1000
 * @param {Number} arg number to modify
 * @param {Number} fixed amount of digits after comma (decimal precision)
 * @return {String} number in num-literal format 10K, 12M, 14B
 */
export default function shortenNumber(arg, fixed = 2) {
  if (arg >= 1000000000) {
    return `${(arg / 1000000000).toFixed(fixed)}B`;
  }
  if (arg >= 1000000) {
    return `${(arg / 1000000).toFixed(fixed)}M`;
  }
  if (arg >= 1000) {
    return `${(arg / 1000).toFixed(fixed)}K`;
  }
  return `${arg}`;
}
