import { CSS_TRANSITION_KEY } from '../const';

/**
 * Get numeric css value from a computed styles of page
 * @param {String} name Name of css var `--` may be omitted
 * @param {Number} fallback Fallback value if value not found
 */
export const get = (name, fallback) => {
  return (
    getComputedStyle(document.documentElement).getPropertyValue(`--${name}`) ||
    fallback
  );
};

/**
 * Set css variable whatever you want
 * @param {String} name Name of a variable to set `--` may be omitted
 * @param {String|Number|Boolean} value Value of css var
 * @param {DOMElement} element value will be written to style attribute of this element
 */
export const set = (name, value, $el = document.documentElement) => {
  return $el.style.setProperty(`--${name}`, value);
};

/**
 * Simple wrapper over `getCssVar` to get transition duration in `ms`. Uses contant key of framework
 */
export const getTransitionDuration = () =>
  parseInt(get(CSS_TRANSITION_KEY), 10) || 250;
