import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';

import './style.css';

export const AUTO_KEY = 'auto';

export const DONE_KEY = 'done';

export const MANUAL_KEY = 'manual';

const getCSSVal = (v = 0) => {
  return { '--LinearProgress-value': `${v}%` };
};

const getStatus = (success, loading) => {
  let newStatus = MANUAL_KEY;
  if (success) {
    newStatus = DONE_KEY;
  } else if (loading) {
    newStatus = AUTO_KEY;
  }

  return newStatus;
};

export default function LinearProgress(props) {
  const { text, success, loading, value, className } = props;
  const [status, setStatus] = useState(getStatus(success, loading));

  useEffect(() => {
    setStatus(getStatus(success, loading));
  }, [value, success, loading]);
  return (
    <div
      className={cn('LinearProgress', className)}
      data-status={status}
      style={getCSSVal(value)}
    >
      <div className='LinearProgress-line' />
      {text && <div className='LinearProgress-text'>{text}</div>}
    </div>
  );
}

assignDisplayName(LinearProgress);

LinearProgress.defaultProps = {
  value: 0
};

LinearProgress.propTypes = {
  /** Indicates that progress is in indeterminate status */
  loading: PT.bool,
  /** Indicates whatever progress was successfully fulfilled */
  success: PT.bool,
  /** Percentage for manual progress representation */
  value: PT.number,
  /** Text to display. May be used to indicate process step */
  text: PT.string,
  /** CCS Class being added to root element */
  className: PT.string
};
