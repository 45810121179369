import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect
} from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import parseNum from '../util/parseNum';
import assignDisplayName from '../util/assignDisplayName';
import { getTransitionDuration } from '../util/cssVar';

import './style.css';

export default function Tabs(props) {
  const { className, children, active, onNavClick, itemClassName } = props;
  const initIndex = onNavClick ? active : 0;
  const $el = useRef();
  const prevTab = useRef(initIndex);
  const [activeTab, setActiveTab] = useState(initIndex);

  useEffect(() => {
    setActiveTab(active);
  }, [active]);

  useLayoutEffect(() => {
    if (activeTab !== prevTab.current) {
      $el.current.classList.add(
        activeTab < prevTab.current ? 'slide-left' : 'slide-right'
      );
      setTimeout(() => {
        $el.current.classList.remove('slide-left');
        $el.current.classList.remove('slide-right');
      }, getTransitionDuration());
    }
    prevTab.current = activeTab;
  }, [activeTab]);

  const buttonClick = useCallback(
    (e) => {
      e.preventDefault();
      const i = parseNum(e.target.dataset.index, 0);
      if (onNavClick) {
        onNavClick(i);
      } else if (i !== activeTab) {
        setActiveTab(i);
      }
    },
    [activeTab, onNavClick]
  );

  return (
    <div className={cn('Tabs', className)}>
      <nav className='Tabs-menu'>
        {children.map(({ key }, i) => (
          <button
            key={key}
            data-index={i}
            className={cn('Tabs-button', 'Button', {
              'state-active': activeTab === i
            })}
            onClick={buttonClick}
          >
            {key}
          </button>
        ))}
      </nav>
      <div className='Tabs-container'>
        <div
          ref={$el}
          data-tab={activeTab}
          className={cn('Tabs-item', itemClassName)}
        >
          {children[activeTab]}
        </div>
      </div>
    </div>
  );
}

assignDisplayName(Tabs);

Tabs.defaultProps = {
  active: 0
};

Tabs.propTypes = {
  /** Index of active Item, Needed for external control */
  active: PT.number,
  /** Handler that will be attached to navigation buttons for external control */
  onNavClick: PT.func,
  /** Additional classname for a root element */
  className: PT.string,
  /** Additional CSS class for item element */
  itemClassName: PT.string,
  /** Contents to render */
  children: PT.node
};
