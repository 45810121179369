/**
 * Simple helper that takes method by keys given and bounds it to context.
 * @param {Object} ctx Context to use and search for functions
 * @param  {...String} names Keys to pick up methods
 */
export default function bindAll(ctx, ...names) {
  names.forEach((name) => {
    ctx[name] = ctx[name].bind(ctx);
  });
}
