import PT from 'prop-types';
import { SORT_DIR_ASC, SORT_DIR_DESC, DATA_RENDERERS } from './const';

export const valueShape = PT.oneOfType([PT.string, PT.number, PT.bool]);

export const flexShape = (props, propName, componentName) => {
  const [flexGrow, flexShrink, flexBasis] = props[propName];
  const isGrowCorrect = typeof flexGrow === 'number' && flexGrow >= 0;
  const isShrinkCorrect = typeof flexShrink === 'number' && flexShrink >= 0;
  const isBasisCorrect = typeof flexBasis === 'number' && flexBasis >= 0;
  // typeof flexBasis === 'string' && (flexBasis === 'auto' || /\d{1,}(px|%)/.test(flexBasis));
  if (!isGrowCorrect || !isShrinkCorrect || !isBasisCorrect) {
    return new Error(
      `${componentName}: prop [${propName}] should match schema [Num >= 0, Num >= 0, (auto|Numpx|0-100%)]. ${props[propName]} was given`
    );
  }
};

export const optionShape = PT.shape({
  isFixed: PT.bool,
  disabled: PT.bool,
  value: valueShape.isRequired,
  description: PT.string,
  label: PT.string.isRequired
});

export const menuItemShape = PT.arrayOf(
  PT.shape({
    key: PT.string.isRequired,
    icon: PT.string,
    label: PT.string.isRequired,
    onClick: PT.func.isRequired
  })
);

export const dataListSchemeShape = PT.arrayOf(
  PT.shape({
    key: PT.string.isRequired,
    label: PT.string.isRequired,
    flex: flexShape,
    sortable: PT.bool,
    maxWidth: PT.number,
    minWidth: PT.number,
    renderer: PT.oneOfType([PT.func, PT.oneOf(Object.values(DATA_RENDERERS))]),
    getter: PT.func
  })
);

export const sortDirectionShape = PT.oneOf([
  SORT_DIR_ASC,
  SORT_DIR_DESC,
  SORT_DIR_ASC.toUpperCase(),
  SORT_DIR_DESC.toUpperCase()
]);

export const refShape = PT.shape({ current: PT.instanceOf(Element) });
