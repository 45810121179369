/**
 * Run function given amount of times with arguments given. Results returned as `Array`
 * @param {Function} func function to run
 * @param {Number} times Amount of times to run it
 * @param  {...any} args Arguments for function (each invocation the same)
 */
export default function runTimes(func, times, ...args) {
  let i = 0;
  const result = new Array(times);
  while (i < times) {
    result[i] = func(i, ...args);
    i += 1;
  }
  return result;
}
