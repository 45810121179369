import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';
import useContextMenu from '../hooks/useContextMenu';
import ContextMenu from '../ContextMenu';

const noop = () => null;

export default function ExpandableValue(props) {
  const { id, data, expanded, className } = props;

  const [open, setOpen] = useState(false);

  const onMouseLeave = useCallback(() => {
    setOpen(false);
  }, []);

  const onMouseEnter = useCallback(() => {
    setOpen(true);
  }, []);

  const { adjustCoords, event, targetRef } = useContextMenu(open);

  return (
    <div
      ref={targetRef}
      className={cn('ExpandableValue', className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {data}
      {open ? (
        <ContextMenu
          id={id}
          event={event}
          className='ExpandableValue-list'
          adjustCoords={adjustCoords}
          onClose={noop}
        >
          {expanded}
        </ContextMenu>
      ) : null}
    </div>
  );
}

assignDisplayName(ExpandableValue);

ExpandableValue.propTypes = {
  /** Id used to create unique portal */
  id: PT.string.isRequired,
  /** Classname for a root element */
  className: PT.string,
  /** Contents rendered as is by default */
  data: PT.node.isRequired,
  /** Expanded content rendered on hover */
  expanded: PT.node.isRequired
};
