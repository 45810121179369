const ANALYZER = /^(>|<|!|%)?(\w*|\d*)(%)?/i;

export default function splitStringToFilters(input, defaultKey) {
  return input
    .split(',')
    .map((item) => item.split(':').map((str) => str.trim()))
    .filter(([key]) => !!key)
    .reduce(
      (acc, [key, expression]) => {
        if (!expression) {
          acc.def.push({
            key: defaultKey,
            op: '%',
            val: key
          });
        } else {
          const [, op, val] = ANALYZER.exec(expression);
          acc.prop.push({
            key,
            op: op || 'eq',
            val
          });
        }
        return acc;
      },
      { def: [], prop: [] }
    );
}
