import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';
import Image from '../Image';

import './style.css';

export default function UserBadge(props) {
  const { fullname, position, image, className, fallback, lazy } = props;
  return (
    <div className={cn('UserBadge', 'is-clearfix', className)}>
      <Image
        ratio='1/1'
        lazy={lazy}
        src={image}
        notfoundtext={null}
        alt={fullname}
        fallback={fallback}
      />
      <span className='UserBadge-fullname'>{fullname}</span>
      <span className='UserBadge-position'>{position}</span>
    </div>
  );
}

assignDisplayName(UserBadge);

UserBadge.defaultProps = {
  lazy: true,
  fullname: 'Not found',
  position: 'undefined'
};

UserBadge.propTypes = {
  /** Whatever user image should be loaded lazily or not */
  lazy: PT.bool,
  /** fallback image to show (Person placeholder) */
  fallback: PT.string,
  /** Formal User name */
  fullname: PT.string,
  /** User avatar to show */
  image: PT.string,
  /** User position */
  position: PT.string,
  /** ClassName pass-through */
  className: PT.string
};
