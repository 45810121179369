/**
 * Simple function that allows to pick subset of data from Object given as rest agruments as key descriptors
 * @param {Object} object target object to pick data from
 * @param {...String} paths keys or path of keys descriptions to pick from
 * @return {Object}
 */
export default function pick(target, ...paths) {
  let i = -1;
  const result = {};

  // eslint-disable-next-line no-plusplus
  while (++i < paths.length) {
    const value = target[paths[i]];
    if (value !== undefined) {
      result[paths[i]] = value;
    }
  }
  return result;
}
