import React, { useState, useCallback, useMemo, forwardRef } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import { Table, Column } from 'react-virtualized';

import assignDisplayName from '../util/assignDisplayName';
import isPlainObject from '../util/isPlainObject';
import runIfExists from '../util/runIfExists';
import { DATA_RENDERERS as KEYS, SORT_DIR_ASC, SORT_DIR_DESC } from '../const';
import { dataListSchemeShape, sortDirectionShape } from '../proptypes';

import Icon from '../Icon';
import Image from '../Image';
import RowSelector from '../RowSelector';
import ContextMenu from '../ContextMenu';
import Popup from '../Popup';
import UserBadge from '../UserBadge';
import Indicator from '../Indicator';
import DateTime from '../DateTime';
import TypeTag from '../TypeTag';
import ExpandableValue from '../ExpandableValue';

import './style.css';

const NULL_INDEX = { index: null };

const SORT_DIR_ICONS = {
  [SORT_DIR_ASC.toUpperCase()]: 'chevron-up',
  [SORT_DIR_DESC.toUpperCase()]: 'chevron-down'
};

export const RENDERERS = {
  [KEYS.USER]: UserBadge,
  [KEYS.STATUS]: Indicator,
  [KEYS.DATETIME]: DateTime,
  [KEYS.TYPE]: TypeTag,
  [KEYS.EXPANDED]: ExpandableValue
};

const mockGetter = () => ({});

function getUtilityColParams(key, width) {
  const className = `DataTable-cell-${key}`;
  return {
    key,
    className,
    headerClassName: className,
    dataKey: key,
    disableSort: true,
    flexGrow: 0,
    flexShrink: 0,
    width
  };
}

function HeaderCellRenderer(props) {
  const { label, disableSort, sortBy, dataKey, sortDirection } = props;
  return (
    <>
      {label}
      {!disableSort && sortBy === dataKey && (
        <Icon icon={SORT_DIR_ICONS[sortDirection]} />
      )}
    </>
  );
}

HeaderCellRenderer.propTypes = {
  label: PT.string,
  disableSort: PT.bool,
  sortBy: PT.string,
  dataKey: PT.string.isRequired,
  sortDirection: sortDirectionShape
};

function DataTable(props, ref) {
  const {
    id,
    width,
    height,
    rowHeight,
    onRowClick,
    onRowDoubleClick,
    onRowsRendered,
    sortBy,
    sortDirection,
    scheme,
    actions,
    sort,
    style,
    loading,
    className,
    headerHeight,
    onSectionRendered,
    loadingRowCount,
    selectable,
    isSelected,
    onSelected,
    enableContextActions,
    getSelectionCount,
    rowCount,
    rowGetter,
    emptyImage,
    emptyImageRatio,
    emptyText
  } = props;
  const finalRowCount = loading
    ? loadingRowCount ||
      Math.floor(height / runIfExists(rowHeight, NULL_INDEX) || rowHeight) - 1
    : rowCount;

  const [contextItem, setContextItem] = useState(null);

  const rootSelector = useMemo(
    () => `#${id} .ReactVirtualized__Table__Grid`,
    [id]
  );

  const contextItemSetter = useCallback(({ event, rowData }) => {
    event.preventDefault();
    event.persist();
    setContextItem({ event, rowData });
  }, []);

  const unsetContextItem = useCallback(() => {
    setContextItem(null);
  }, []);

  const rowClassName = useCallback(
    ({ index }) => {
      if (index < 0) {
        return 'DataTable-header';
      }
      const rowData = selectable ? rowGetter({ index }) : null;
      return cn('DataTable-row', 'nofocusvisible', {
        'DataTable-loading-placeholder': loading,
        'state-selected': selectable && isSelected(rowData && rowData.id)
      });
    },
    [isSelected, loading, rowGetter, selectable]
  );

  const getPopupYBoundary = useCallback(() => {
    const $el = document.querySelector(rootSelector);
    if (!$el) return window.outerHeight;
    const { bottom } = $el.getBoundingClientRect();
    return bottom;
  }, [rootSelector]);

  const getPopupScrollParent = useCallback(
    () => document.querySelector(rootSelector),
    [rootSelector]
  );

  return (
    <>
      {enableContextActions && actions && contextItem ? (
        <ContextMenu
          key='contextmenu'
          id={`${id}-contextmenu`}
          event={contextItem.event}
          onClose={unsetContextItem}
        >
          {actions(contextItem.rowData)}
        </ContextMenu>
      ) : null}
      <Table
        key='datatable'
        id={id}
        ref={ref ? ref : null}
        width={width}
        height={height}
        rowHeight={rowHeight}
        onRowClick={onRowClick}
        onRowDoubleClick={onRowDoubleClick}
        sortBy={sortBy}
        sortDirection={
          typeof sortDirection === 'string' ? sortDirection.toUpperCase() : null
        }
        headerHeight={
          headerHeight || runIfExists(rowHeight, NULL_INDEX) || rowHeight
        }
        style={style}
        overscanRowCount={2}
        className={cn('DataTable', className, {
          'has-actions': !!actions,
          'state-loading': loading
        })}
        rowCount={finalRowCount}
        sort={!loading ? sort : null}
        headerClassName='DataTable-header-cell'
        onRowsRendered={onRowsRendered}
        onRowRightClick={
          enableContextActions && actions ? contextItemSetter : null
        }
        onSectionRendered={onSectionRendered}
        rowGetter={loading ? mockGetter : rowGetter}
        rowClassName={rowClassName}
        noRowsRenderer={() => (
          <div className='DataTable-no-data'>
            <Image src={emptyImage} ratio={emptyImageRatio} />
            <div className='DataTable-no-data-text'>{emptyText}</div>
          </div>
        )}
      >
        {selectable ? (
          <Column
            {...getUtilityColParams('selection', 24)}
            headerRenderer={() => {
              return (
                <RowSelector
                  selected={!!getSelectionCount()}
                  icon='cross'
                  value={null}
                  onClick={onSelected}
                />
              );
            }}
            cellRenderer={({ rowData }) => {
              if (loading) return null;
              return (
                <RowSelector
                  value={rowData.id}
                  selected={isSelected(rowData.id)}
                  onClick={onSelected}
                />
              );
            }}
          />
        ) : null}
        {scheme.map((item) => {
          const {
            key,
            flex,
            sortable,
            renderer,
            getter,
            label,
            minWidth,
            maxWidth
          } = item;
          return (
            <Column
              key={key}
              dataKey={key}
              label={label}
              maxWidth={maxWidth}
              minWidth={minWidth}
              disableSort={!sortable}
              flexGrow={flex[0]}
              flexShrink={flex[1]}
              width={flex[2]}
              headerRenderer={HeaderCellRenderer}
              cellDataGetter={loading ? mockGetter : getter}
              cellRenderer={({ cellData }) => {
                if (loading) return null;
                const Component =
                  typeof renderer === 'function'
                    ? renderer
                    : RENDERERS[renderer];
                const componentData = isPlainObject(cellData)
                  ? cellData
                  : { data: cellData };
                if (Component) {
                  return <Component {...componentData} />;
                }
                return componentData.data;
              }}
            />
          );
        })}
        {actions ? (
          <Column
            {...getUtilityColParams('actions', 36)}
            cellRenderer={({ rowData }) => {
              return !loading ? (
                <Popup
                  id={`actions-${id}-${rowData.id}`}
                  getYBoundary={getPopupYBoundary}
                  getScrollParent={getPopupScrollParent}
                >
                  {actions(rowData)}
                </Popup>
              ) : null;
            }}
          />
        ) : null}
      </Table>
    </>
  );
}

export default forwardRef(DataTable);

// Register within namespace
// ==================================================================
assignDisplayName(DataTable);

// PropTypes declaration
// ==================================================================
DataTable.propTypes = {
  /** Unqiue ID for a root datatable element and context menu */
  id: PT.string.isRequired,
  /** Just default className pass-through */
  className: PT.string,
  /** Config that determines columns to display and their type and size */
  scheme: dataListSchemeShape.isRequired,
  /** Enable context-menu to provide right-click expirience */
  enableContextActions: PT.bool,
  /** Defines set of handlers that are being used to generate Menu from */
  actions: PT.func,
  /** see `useDataSoring` Indicates what property is used for a sorting at the moment */
  sortBy: PT.string,
  /** see `useDataSoring` Sorting direction asc or desc */
  sortDirection: sortDirectionShape,
  /** see `useDataSoring` Handler being called when collection should be re-sorted */
  sort: PT.func,
  /** Indicates whatever content is being loaded */
  loading: PT.bool,
  /** How many placeholder rows to show upon loading animation */
  loadingRowCount: PT.number,
  /** see `useSelection` Indicates whatever selection functionality is enabled */
  selectable: PT.bool,
  /** see `useSelection` Function used to compare item id against selection `(String|Number) => Boolean` */
  isSelected: PT.func,
  /** see `useSelection` Handler called upon new item is selected */
  onSelected: PT.func,
  /** see `useSelection` Get number of items selected */
  getSelectionCount: PT.func,
  /** *react-virtualized* Width of a container */
  width: PT.number.isRequired,
  /** *react-virtualized* Height of a container */
  height: PT.number.isRequired,
  /** Current amount of items in a collection */
  rowCount: PT.number.isRequired,
  /** Height of each row in pixels */
  rowHeight: PT.oneOfType([PT.number, PT.func]).isRequired,
  /** Optional height for a header row, [rowHeight] if not provided */
  headerHeight: PT.number,
  /** Function called when a row being clicked */
  onRowClick: PT.func,
  /** Function called when a row being double-clicked */
  onRowDoubleClick: PT.func,
  /** Function called when a section of a table being renderered */
  onSectionRendered: PT.func,
  /** Function used to get data for a row */
  rowGetter: PT.func.isRequired,
  /** Image for an empty placeholder renderer */
  emptyImage: PT.string,
  /** Aspect ration for an image above */
  emptyImageRatio: PT.string,
  /** Text for an empty placeholder renderer */
  emptyText: PT.oneOfType([PT.string, PT.node]),
  /** function invoked when current section of table is rendered */
  onRowsRendered: PT.func,
  /** Style attached to root <Table> element */
  style: PT.objectOf(PT.oneOfType([PT.string, PT.number]))
};
