import { useCallback, useState } from 'react';
import parseNum from '../util/parseNum';
import runIfExists from '../util/runIfExists';

export default function useFormInterface({ onChange, onBlur }) {
  const [pristine, setPristine] = useState(true);
  const [error, setError] = useState(null);

  return [
    pristine,
    error,
    {
      onChange: useCallback(
        ({ target }) => {
          const changeArgument = {
            id: target.id,
            name: target.name,
            value: target.value
          };
          switch (target.type) {
            case 'number':
              changeArgument.value = Number.isFinite(target.valueAsNumber)
                ? target.valueAsNumber
                : parseNum(target.value, 0);
              break;
            case 'date':
              changeArgument.valueAsDate = target.valueAsDate;
              break;
            case 'checkbox':
            case 'radio':
              changeArgument.checked = target.checked;
              break;
            default:
              break;
          }
          return onChange(changeArgument);
        },
        [onChange]
      ),
      onBlur: useCallback(
        (e) => {
          setPristine(undefined);
          if (!e.target.valid) {
            setError(e.target.validationMessage);
          }
          return runIfExists(onBlur, e);
        },
        [onBlur]
      )
    }
  ];
}
