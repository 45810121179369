import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';

import './style.css';

export default function Fieldset(props) {
  const { className, title, children } = props;
  return (
    <fieldset className={cn('Fieldset', className)}>
      {title ? <legend>{title}</legend> : null}
      <div className='Fieldset-content'>{children}</div>
    </fieldset>
  );
}

assignDisplayName(Fieldset);

Fieldset.propTypes = {
  /** Additional classname for a root element */
  className: PT.string,
  /** Corresponds to content of <legend> - title of fieldset */
  title: PT.oneOfType([PT.string, PT.node]).isRequired,
  /** Contents of fieldset */
  children: PT.node
};
