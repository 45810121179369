import isPlainObject from './isPlainObject';

/**
 * lodash-like property setter, works with nested objects
 * @param {Object} obj target object to pick a property from
 * @param {String} path key descriptor . dot marks nested object property
 * @param {Any} value new value of given property
 */
export default function set(obj, path, value) {
  const trace = path.split('.');
  const key = trace.pop();
  let target = obj;

  let i = 0;
  const { length } = trace;

  while (target && i < length) {
    if (target[trace[i]] === undefined) {
      target[trace[i]] = {};
    }
    target = target[trace[i]];
    i += 1;
  }
  if (isPlainObject(target)) {
    target[key] = value;
  }
  return obj;
}
