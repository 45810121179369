import React, { useCallback } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';
import useFileUploader from '../hooks/useFileUploader';
import Image from '../Image';

import './style.css';

const ALLOWED_IMG_SIZE = 0.5 * 1024 * 1024; // 500Kb

const ALLOWED_IMG_TYPES = ['jpg', 'png', 'svg', 'webp', 'jpeg', 'gif'];

export default function ImageUpload(props) {
  const {
    className,
    onChange,
    disabled,
    message,
    placeholder,
    showFilename,
    onFocus,
    onBlur,
    name,
    id,
    value,
    error,
    maxSize,
    fileType
  } = props;

  const onChangeCallback = useCallback(
    (file) => onChange({ name, value: file }),
    [onChange, name]
  );

  const [warning, image, onInputChange] = useFileUploader(
    onChangeCallback,
    maxSize,
    fileType
  );
  const textMessage = error || warning || message;
  const src = image ? image.url : value || placeholder;
  return (
    <div
      className={cn('ImageUpload', className, {
        'state-disabled': disabled,
        'state-selected': !!value || !!image,
        'state-warning': warning && !error,
        'state-error': error
      })}
    >
      <div className='ImageUpload-preview'>
        <Image
          src={src}
          className='ImageUpload-image'
          lazy={false}
          fallback={placeholder}
          ratio='1'
        />
        <input
          id={id}
          name={name}
          type='file'
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onInputChange}
        />
        {showFilename && (value || image) && (
          <span className='ImageUpload-filename'>{value || image.name}</span>
        )}
      </div>
      {textMessage && (
        <span className='ImageUpload-message'>{textMessage}</span>
      )}
    </div>
  );
}

assignDisplayName(ImageUpload);

ImageUpload.defaultProps = {
  placeholder: 'user-placeholder.svg',
  maxSize: ALLOWED_IMG_SIZE,
  fileType: ALLOWED_IMG_TYPES,
  showFilename: false,
  message: 'Click and choose file or drop one.'
};

ImageUpload.propTypes = {
  /** ID of input */
  id: PT.string.isRequired,
  /** Additional classname for root element  */
  className: PT.string,
  /** Function called upon file selection */
  onChange: PT.func.isRequired,
  /** Indicates whatever this control is enabled or not */
  disabled: PT.bool,
  /** This function will be fired if input is under focus */
  onFocus: PT.func,
  /** This function will be fired when input losing focus */
  onBlur: PT.func,
  /** Placeholder image shown before anything is uploaded */
  placeholder: PT.string,
  /** Controls whatever filename should be shown */
  showFilename: PT.bool,
  /** Name attribute for file input */
  name: PT.string.isRequired,
  /** Determines maximum allowed size  */
  maxSize: PT.number,
  /** Show if error happened related to component */
  error: PT.string,
  /** Image url if already selected one */
  value: PT.string,
  /** Helper text  */
  message: PT.string,
  /** Array controls file extensions allowed for an upload */
  fileType: PT.arrayOf(PT.oneOf(ALLOWED_IMG_TYPES))
};
