import { SORT_DIR_DESC, SORT_DIR_ASC } from '../const';
import invalid from './invalid';
import get from './get';

const DIR_INDEX = {
  [SORT_DIR_DESC]: -1,
  [SORT_DIR_ASC]: 1
};

/**
 * Generalized wrapper running `Array.sort` against data given and sorts by specific `Number\|String` property.
 * @param {Object[]} data Data to sort
 * @param {String} sortBy Property to sort by
 * @param {*} sortDirection Direction of sorting
 */
export default function collectionSort(data, sortBy, sortDirection) {
  return data.sort((a, b) => {
    const dir = DIR_INDEX[sortDirection];
    invalid(
      dir !== undefined,
      `Direction is required for sorting, should be: ${SORT_DIR_DESC} or ${SORT_DIR_ASC}, but ${sortDirection} was given.`
    );
    let parA = get(a, sortBy);
    let parB = get(b, sortBy);
    if (typeof parA === 'string') {
      parA = parA.toUpperCase();
    }
    if (typeof parB === 'string') {
      parB = parB.toUpperCase();
    }
    if (parA < parB) {
      return -1 * dir;
    }
    if (parA > parB) {
      return 1 * dir;
    }
    return 0;
  });
}
