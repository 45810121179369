/**
 * Simple wrapper intended to perform conversion of a given sting from snake/dash case to camelCase
 * e.g. some-long-string to someLongString
 * @param {String} s string key to convert
 * @return {String} camelCase string
 */
export default function toCamelCase(s) {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
}
