import React, { useCallback } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';
import runIfExists from '../util/runIfExists';
import { optionShape, valueShape } from '../proptypes';
import Toggler from '../Toggler';

import './style.css';

const isChecked = {
  radio: (value, needle) => value === needle,
  checkbox: (value, needle) => value.some((v) => v === needle)
};

export default function TogglerGroup(props) {
  const {
    id,
    value,
    type,
    options,
    name,
    onChange,
    onFocus,
    onBlur,
    disabled,
    className
  } = props;

  const changeHandler = useCallback(
    (change) => {
      let newValue = change.value;
      if (type === 'checkbox') {
        newValue = new Set(value);
        const method = change.checked ? 'add' : 'delete';
        newValue[method](change.value);
        newValue = [...newValue];
      }
      onChange({
        id,
        name,
        value: newValue,
        optionId: change.id
      });
    },
    [onChange, type, id, name, value]
  );

  const blurHandler = useCallback((e) => runIfExists(onBlur, e), [onBlur]);

  const focusHandler = useCallback((e) => runIfExists(onFocus, e), [onFocus]);

  return (
    <ul className={cn('TogglerGroup', className)} id={id}>
      {options.map((option) => {
        const checked = isChecked[type](value, option.value);
        const key = `${id}-${option.value}`;
        return (
          <li key={key} className='TogglerGroup-item'>
            <Toggler
              id={key}
              checked={checked}
              name={name}
              type={type}
              disabled={disabled || option.disabled}
              label={option.label}
              value={option.value}
              onFocus={focusHandler}
              message={option.description}
              onBlur={blurHandler}
              onChange={changeHandler}
            />
          </li>
        );
      })}
    </ul>
  );
}

assignDisplayName(TogglerGroup);

TogglerGroup.propTypes = {
  /** Id for input and label */
  id: PT.string.isRequired,
  /** Value of input REQUIRED */
  value: PT.oneOfType([valueShape, PT.arrayOf(valueShape)]).isRequired,
  /** change handler REQUIRED */
  onChange: PT.func.isRequired,
  /** This function will be fired if input is under focus */
  onFocus: PT.func,
  /** This function will be fired when input losing focus */
  onBlur: PT.func,
  /** Controls state of checkbox */
  options: PT.arrayOf(optionShape).isRequired,
  /** Name of input REQUIRED */
  name: PT.string.isRequired,
  /** Indicates whatever this control is enabled or not */
  disabled: PT.bool,
  /** CSS Class for a root element */
  className: PT.string,
  /** Type of switcher checkbox or radio */
  type: PT.oneOf(['checkbox', 'radio']).isRequired
};
