/**
 * Partially apply given function, arguments given will be listed *BEFORE* arguments given during execution of resulting function
 * @param {Function} func Function to execute
 * @param  {...any} partials Pre-defined arguments to pass
 * @returns {Function} Result function
 */
export function partial(func, ...partials) {
  return (...args) => func(...partials, ...args);
}

/**
 * Partially apply given function, arguments given will be listed *AFTER* arguments given during execution of resulting function
 * @param {Function} func Function to execute
 * @param  {...any} partials Pre-defined arguments to pass
 * @returns {Function} Result function
 */
export function partialRight(func, ...partials) {
  return (...args) => func(...args, ...partials);
}
