/**
 * lodash-like property getter, works with nested objects
 * @param {Object} obj target object to pick a property from
 * @param {String} path key descriptor . dot marks nested object property
 * @param {Any} fallback fallback value used if key is not found
 */
export default function get(target, path, fallback) {
  const trace = path.split('.');
  let result = target;
  let i = 0;

  while (
    i < trace.length &&
    result !== null &&
    result !== undefined &&
    Object.hasOwnProperty.call(result, trace[i])
  ) {
    result = result[trace[i]];
    i += 1;
  }
  return i === trace.length ? result : fallback;
}
