/**
 * Generic debounce handler implementation
 * @param {Function} func Function to debounce
 * @param {Number} wait Timeout in ms between invokations
 * @param {Boolean} immediate Whatever function should fire immideately upon first inst
 */
export default function debounce(func, wait, immediate) {
  let timeout;
  return (...args) => {
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func(...args);
    }, wait);
    if (callNow) func(...args);
  };
}
