import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import useFormInterface from '../hooks/useFormInterface';
import assignDisplayName from '../util/assignDisplayName';
import pick from '../util/pick';
import { valueShape } from '../proptypes';

import './style.css';

const ELPROPS = [
  'name',
  'value',
  'type',
  'tabIndex',
  'checked',
  'required',
  'disabled',
  'onFocus'
];

export default function Toggler(props) {
  const { id, message, label, className, onChange, onBlur } = props;
  const elProps = pick(props, ...ELPROPS);

  const [pristine, error, handlers] = useFormInterface({ onChange, onBlur });

  return (
    <div
      className={cn('Toggler', className, {
        'state-error': error
      })}
    >
      <input {...elProps} {...handlers} id={id} data-pristine={pristine} />
      {label ? (
        <label htmlFor={id} className='Toggler-label'>
          {label}
        </label>
      ) : null}
      {(error || message) && (
        <p className='Toggler-message'>{error || message}</p>
      )}
    </div>
  );
}

assignDisplayName(Toggler);

Toggler.propTypes = {
  /** Id for input and label */
  id: PT.string.isRequired,
  /** Value of input REQUIRED */
  value: valueShape.isRequired,
  /** change handler REQUIRED */
  onChange: PT.func.isRequired,
  /** This function will be fired if input is under focus */
  onFocus: PT.func,
  /** This function will be fired when input losing focus */
  onBlur: PT.func,
  /** Controls element focusability */
  tabIndex: PT.number,
  /** Input label */
  label: PT.string,
  /** Controls state of checkbox */
  checked: PT.bool.isRequired,
  /** Name of input REQUIRED */
  name: PT.string.isRequired,
  /** Indicates whatever this control is enabled or not */
  disabled: PT.bool,
  /** CSS Class for a root element */
  className: PT.string,
  /** Type of switcher checkbox or radio */
  type: PT.oneOf(['checkbox', 'radio']).isRequired,
  /** Addtional optional description */
  message: PT.string,
  /** Whatever this toggler is required */
  required: PT.bool
};
