import { useState, useCallback } from 'react';
import { runIfExists } from '../util';

export default function useFileUploader(
  onChange,
  maxSize,
  fileType,
  method = 'readAsDataURL'
) {
  const [imageData, setImageData] = useState(null);
  const [error, setError] = useState(null);

  const onChangeHandler = useCallback(
    (e) => {
      e.preventDefault();

      const reader = new FileReader();
      const file = e.target.files[0];

      reader.addEventListener('load', () => {
        runIfExists(onChange, file);
        setError(null);
        setImageData({
          url: reader.result,
          name: file.name
        });
      });

      if (fileType && fileType.every((type) => file.type !== `image/${type}`)) {
        setError(
          `File type error: ${
            file.type
          } given, Should be one of: ${fileType.join(', ')}`
        );
      } else if (file.size > maxSize) {
        setError(`File size error: ${maxSize / 1024 / 1024}Mb max.`);
      } else {
        reader[method](file);
      }
    },
    [onChange, maxSize, fileType, method]
  );

  return [error, imageData, onChangeHandler];
}
