export const CSS_TRANSITION_KEY = 'transition-duration';

export const SORT_DIR_ASC = 'asc';

export const SORT_DIR_DESC = 'desc';

export const CTX_MENU_EVT = 'tr_ui_close_signal';

export const DATA_RENDERERS = {
  USER: 'user',
  STATUS: 'status',
  DATETIME: 'datetime',
  LIST: 'list',
  TYPE: 'type',
  EXPANDED: 'expanded'
};

export const NOTIFICATION_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
};

export const TEXT_INPUT_TYPES = [
  'text',
  'email',
  'number',
  'url',
  'tel',
  'date',
  'password',
  'search'
];

export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const URL_REGEX = /^(https?):\/\/[^ "]+$/;
