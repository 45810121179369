import { useState, useCallback, useRef, useLayoutEffect } from 'react';

export default function useContextMenu(open) {
  const $target = useRef();

  const [event, setEvent] = useState({ clientY: 0, clientX: 0 });

  const setCoords = useCallback(() => {
    if (open) {
      const rect = $target.current.getBoundingClientRect();
      setEvent({
        clientX: rect.right,
        clientY: rect.bottom
      });
    }
  }, [open]);

  useLayoutEffect(() => {
    setCoords();
  }, [setCoords]);

  const adjustCoords = useCallback(({ width, bottom, height }) => {
    const target = $target.current.getBoundingClientRect();

    const result = {
      left: target.right > width ? target.right - width : target.left
    };

    if (window.innerHeight < bottom) {
      result.top = target.top - height;
    }

    return result;
  }, []);

  return { adjustCoords, event, targetRef: $target, setCoords };
}
