import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import pick from '../util/pick';
import useFormInterface from '../hooks/useFormInterface';
import assignDisplayName from '../util/assignDisplayName';
import FormControl from '../FormControl';

import './style.css';

const ELPROPS = [
  'rows',
  'name',
  'autoFocus',
  'disabled',
  'required',
  'placeholder',
  'value',
  'tabIndex',
  'onFocus'
];

export default function TextArea(props) {
  const {
    id,
    error,
    resize,
    label,
    message,
    onChange,
    onBlur,
    autocomplete,
    className
  } = props;
  const elProps = pick(props, ...ELPROPS);
  const [pristine, innerError, handlers] = useFormInterface({
    onChange,
    onBlur
  });
  return (
    <FormControl
      id={id}
      className={cn('TextArea', className)}
      label={label}
      message={message}
      error={error || innerError}
    >
      <textarea
        id={id}
        data-pristine={pristine}
        data-resizable={resize}
        autoComplete={autocomplete || undefined}
        {...elProps}
        {...handlers}
      />
    </FormControl>
  );
}

assignDisplayName(TextArea);

TextArea.propTypes = {
  /** Id for textarea */
  id: PT.string.isRequired,
  /** Input label */
  label: PT.string,
  /** Additional description for a field will be overriden if [error] provided */
  message: PT.node,
  /** Value of input REQUIRED */
  value: PT.string.isRequired,
  /** change handler REQUIRED */
  onChange: PT.func.isRequired,
  /** Name of input REQUIRED */
  name: PT.string.isRequired,
  /** CSS Class for a root element */
  className: PT.string,
  /** Controls element focusability */
  tabIndex: PT.number,
  /** Corresponds to [rows] attribute of <textarea> */
  rows: PT.number,
  /** Whatever textarea should be resizable */
  resize: PT.bool,
  /** This function will be fired if input is under focus */
  onFocus: PT.func,
  /** This function will be fired when input losing focus */
  onBlur: PT.func,
  /** Indicates whatever this control is enabled or not */
  disabled: PT.bool,
  /** Placeholder for an input */
  placeholder: PT.string,
  /** Corresponds to [autocomplete] input attribute */
  autocomplete: PT.string,
  /** Indicate that this input is required and cannot be omitted */
  required: PT.bool,
  /** Explicitly set error for given control */
  error: PT.string
};
