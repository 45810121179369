/**
 * Simple handler to check whatever given values is a simple Object
 * @param {Any} arg Value to test
 * @returns {Boolean}
 */
export default function isPlainObject(arg) {
  if (arg === null || Array.isArray(arg)) {
    return false;
  }
  return (
    typeof arg === 'object' && Object.getPrototypeOf(arg) === Object.prototype
  );
}
