import React, { useCallback } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';
import runOnKey from '../util/runOnKey';
import { valueShape } from '../proptypes';

import './style.css';

export default function Switcher(props) {
  const {
    id,
    value,
    name,
    onChange,
    onFocus,
    tabIndex,
    options,
    onBlur,
    label,
    message,
    disabled,
    className
  } = props;
  const [opt1, opt2] = options;
  const onChangeHandler = useCallback(
    ({ type, target }) => {
      const condition = type === 'keydown' ? !target.checked : target.checked;
      return onChange({
        name,
        id,
        value: condition ? opt2 : opt1
      });
    },
    [onChange, name, id, opt1, opt2]
  );

  return (
    <div
      className={cn('Switcher', className, {
        'state-disabled': disabled
      })}
    >
      <div className='Switcher-holder'>
        <label htmlFor={id}>{label}</label>
        <div className='Switcher-element'>
          <input
            id={id}
            name={name}
            type='checkbox'
            onBlur={onBlur}
            onFocus={onFocus}
            tabIndex={tabIndex}
            disabled={disabled}
            checked={value === opt2}
            onChange={onChangeHandler}
            onKeyDown={runOnKey('Enter', onChangeHandler)}
          />
          <span className='Switcher-point' />
        </div>
      </div>
      {message && <p className='Switcher-message'>{message}</p>}
    </div>
  );
}

assignDisplayName(Switcher);

Switcher.propTypes = {
  /** Id for input and label */
  id: PT.string.isRequired,
  /** Value of input REQUIRED */
  value: valueShape.isRequired,
  /** change handler REQUIRED */
  onChange: PT.func.isRequired,
  /** Options to choose between REQUIRED */
  options: PT.arrayOf(valueShape).isRequired,
  /** This function will be fired if input is under focus */
  onFocus: PT.func,
  /** This function will be fired when input losing focus */
  onBlur: PT.func,
  /** Controls element focusability */
  tabIndex: PT.number,
  /** Label text to describe purpose of a Switcher */
  label: PT.string.isRequired,
  /** Name of input REQUIRED */
  name: PT.string.isRequired,
  /** Indicates whatever this control is enabled or not */
  disabled: PT.bool,
  /** CSS Class for a root element */
  className: PT.string,
  /** Addtional optional description */
  message: PT.string
};
