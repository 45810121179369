import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';

import './style.css';

export default function Counter(props) {
  const { data, className, title, theme } = props;
  return (
    <b className={cn('Counter', className, `theme-${theme}`)} title={title}>
      {data}
    </b>
  );
}

assignDisplayName(Counter);

Counter.defaultProps = {
  data: 0
};

Counter.propTypes = {
  /** Controls visual representation of counter */
  theme: PT.oneOf(['accent', 'secondary']),
  /** Data to show, some kind of number or string */
  data: PT.oneOfType([PT.string, PT.number]),
  /** classname to customize element */
  className: PT.string,
  /** Optional title attribute if needed */
  title: PT.string
};
