import r from './getRandomArbitrary';

const dict = 'abcdefghijklmnopqrstvuwxyzABCDEFGHIJKLMNOPQRSTVUWXYZ0123456789';

/**
 * Simple function to generate qunieue ID via randon symbols in accordance to scheme provided
 * @param  {...Number} scheme numbers as arguments delimit length of symbol chunk
 * @return {String} string id
 */
export default function uniqueId(...scheme) {
  return scheme
    .map((num) => {
      let result = '';
      while (result.length <= num) {
        result += dict[Math.floor(r(0, dict.length))];
      }
      return result;
    })
    .join('-');
}
