import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';

import './style.css';

export default function FormControl(props) {
  const { id, label, error, message, inline, className, children } = props;
  return (
    <div
      className={cn('FormControl', className, {
        'is-inline': !!inline,
        'state-error': error
      })}
      style={inline && { '--FormControl-basis': inline }}
    >
      {label &&
        (id ? (
          <label htmlFor={id} className='FormControl-label'>
            {label}
          </label>
        ) : (
          <h4 className='label-replacement FormControl-label'>{label}</h4>
        ))}
      <div className='FormControl-holder'>{children}</div>
      {(error || message) && (
        <p className='FormControl-message'>{error || message}</p>
      )}
    </div>
  );
}

assignDisplayName(FormControl);

FormControl.propTypes = {
  /** Id for input and label */
  id: PT.string,
  /** Input label */
  label: PT.string,
  /** CSS Class for a root element */
  className: PT.string,
  /** Additional description for a field will be overriden if [error] or [warning] provided */
  message: PT.node,
  /** Error message to display, also sets input styles to error state */
  error: PT.node,
  /** Actual form element to wrap and display */
  children: PT.node.isRequired,
  /** Defines whatever label should be on one line with a Input, may accept any CSS value as flex-basis for input or 'auto' */
  inline: PT.oneOfType([PT.bool, PT.string])
};
