import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';

import './style.css';

export default function TypeTag(props) {
  const { data, className, title } = props;
  return (
    <span
      className={cn('TypeTag', className, `is-${data.toLowerCase()}`)}
      title={title}
    >
      {data}
    </span>
  );
}

assignDisplayName(TypeTag);

TypeTag.propTypes = {
  /** One of feed types - contents to render */
  data: PT.oneOf([
    'facebook',
    'twitter',
    'rss',
    'reddit',
    'html',
    'youtube',
    'telegram'
  ]).isRequired,
  /** classname to customize element */
  className: PT.string,
  /** Optional title attribute if needed */
  title: PT.string
};
