/**
 * Run function if it exists. Just helper to eliminate common conditions
 * @param {Function} func proposed function
 * @param  {...any} args arguments for function
 */
export default function runIfExists(func, ...args) {
  if (typeof func === 'function') {
    return func(...args);
  }
  return undefined;
}
