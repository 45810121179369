/**
 * Simple wrapper that runs given function only if keyboard event fired and certain key is pressed
 * @param {String} key Key name to look for
 * @param {Function} func Function to run
 * @param  {...any} args Arguments to pass
 */
export default function runOnKey(key, func, ...args) {
  const isArray = Array.isArray(key);
  return (e) => {
    if (e.key && (isArray ? key.some((k) => e.key === k) : e.key === key)) {
      func(e, ...args);
    }
  };
}
