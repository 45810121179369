/**
 * Simple function that returns number of own enumerable keys for given `object` in a optimized way
 * @param {Object} obj Object for accountance
 */
export default function countKeys(obj) {
  const hasOwn = Object.prototype.hasOwnProperty;
  let count = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const k in obj) {
    if (hasOwn.call(obj, k)) {
      count += 1;
    }
  }
  return count;
}
