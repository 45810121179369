/**
 Create new empty DOM element with attributes and content given
 * @param {String} el tagname of a new element to create
 * @param {Object} attrs value of ID attribute for a new div
 * @return {Element} new DOM element
 ======================================================================================== */
export default function createElement(
  el = 'div',
  attrs = {},
  innerHTML = null
) {
  const result = document.createElement(el);
  Object.entries(attrs).forEach(([key, val]) => {
    if (val !== undefined) {
      result.setAttribute(key, val);
    }
  });
  result.innerHTML = innerHTML;
  return result;
}
