/**
 * Run function once if data is an `Object` or for every item if `Object[]`
 * @param {Function} func function to run
 * @param {Object|Array} data data to run function against
 * @param  {...any} args Additional arguments passed to a function
 */
export default function mapOrApply(func, data, ...args) {
  return Array.isArray(data)
    ? data.map((item) => func(item, ...args))
    : func(data, ...args);
}
