import { useEffect, useState, useCallback, useMemo } from 'react';
import { debounce, splitStringToFilters, filterAplicator } from '../util';

/* Simple hook that stores input value
   and create function that parses it into filters
   and apply it to data given
  ================================================================ */
export default function useDataFiltering(
  defaultKey,
  delay = 300,
  minInputLength = 3
) {
  const [inputValue, setValue] = useState('');
  const [filter, setFilter] = useState(null);

  const setFilterHandler = useMemo(
    () => debounce(setFilter, delay),
    [setFilter, delay]
  );

  useEffect(() => {
    if (inputValue.length >= minInputLength) {
      setFilterHandler(() => {
        const { def, prop } = splitStringToFilters(inputValue, defaultKey);

        if (!def.length && !prop.length) {
          return null;
        }

        return filterAplicator({ def, prop });
      });
    } else if (!inputValue.length) {
      setFilter(null);
    }
  }, [defaultKey, inputValue, minInputLength, setFilterHandler]);

  const getFilters = useCallback(({ value }) => setValue(value), []);

  return { filter, inputValue, getFilters };
}
