import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import PT from 'prop-types';
import useBodyClick from '../hooks/useBodyClick';
import useCtxChannel from '../hooks/useCtxChannel';
import assignDisplayName from '../util/assignDisplayName';

// Component declaration
// ==================================================================
export default function ContextMenu(props) {
  const {
    children,
    onClose,
    className,
    id,
    role,
    event = {},
    adjustCoords
  } = props;
  const $el = useRef();

  // Close menu if clicked elsewhere
  // ==================================================================
  useBodyClick($el, onClose);

  // Adjust position of menu if it close to borders
  // ==================================================================
  useEffect(() => {
    const { top, left } = adjustCoords(
      $el.current.getBoundingClientRect(),
      event
    );
    Object.assign($el.current.style, { top: `${top}px`, left: `${left}px` });
  }, [event, adjustCoords]);

  useCtxChannel(onClose, true);

  const CONTENT = (
    <div
      ref={$el}
      id={id}
      className={cn('ContextMenu', className, 'layout-contextmenu')}
      style={{
        top: event.clientY,
        left: event.clientX
      }}
      role={role}
      onClick={onClose}
      onKeyDown={onClose}
    >
      {children}
    </div>
  );

  return createPortal(CONTENT, document.body);
}

// Register within namespace
// ==================================================================
assignDisplayName(ContextMenu);

// PropTypes declaration
// ==================================================================
ContextMenu.propTypes = {
  /** Unique ID for a menu component */
  id: PT.string.isRequired,
  /** WAI-ARIA role for component defaults to "menu" */
  role: PT.string,
  /** ClassName for a root element */
  className: PT.string,
  /** Click event used to determine coords for a menu */
  event: PT.shape({ clientX: PT.number, clientY: PT.number }).isRequired,
  /** This callback invoked on body click, put your closing logic here */
  onClose: PT.func.isRequired,
  /** Menu contents */
  children: PT.node,
  /** Func used to adjust coords in relation to viewport */
  adjustCoords: PT.func
};

ContextMenu.defaultProps = {
  role: 'menu',
  /**
   * This function is made to adjust position of menu if it being out of viewport
   * @param {Element} $list DOM element being rendererd
   * @param {Element|Event|Object} $target Target for element
   * @returns {Object} with top and left keys equals to new adjusted position
   */
  adjustCoords: ($list, $target) => {
    const result = {};
    const { bottom, right, height, width } = $list;
    if (window.innerHeight < bottom) {
      result.top = $target.clientY - height;
    }
    if (window.innerWidth < right) {
      result.left = $target.clientX - width;
    }
    return result;
  }
};
